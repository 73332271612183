<template>
  <div class="privacy-policy container-data">
    <Navbar :noData="true" :loggedIn="0"/>
    <div class="content">
      <h1>Termos e Condições de Uso da PRATTU para Estabelecimentos PARCEIROS</h1>

      <h2>1. Informações da Empresa</h2>
      <ul>
          <li><strong>Nome da Empresa:</strong> Prattu Inovação e Tecnologia Ltda.</li>
          <li><strong>Endereço Completo:</strong> Alameda dos Guatás, 1694.</li>
          <li><strong>Contato:</strong> suporte@prattuapp.com.br</li>
      </ul>

      <h2>2. Descrição do Serviço</h2>
      <p>
          A PRATTU é um marketplace voltado para o segmento de food service, que permite a interação entre restaurantes, 
          lanchonetes, bares e outros negócios do setor alimentício cadastrados (PARCEIROS) com consumidores (USUÁRIOS).
          Através da plataforma PRATTU, os estabelecimentos comerciais cadastrados (PARCEIROS) têm a oportunidade de 
          expandir a sua visibilidade e alcance no mercado, anunciando e disponibilizando seus produtos diretamente aos USUÁRIOS.
      </p>
      <p>
          A PRATTU não é produtora, fornecedora, distribuidora e não realiza a comercialização de qualquer produto, mas apenas permite que 
          se estabeleça a relação de compra entre o consumidor USUÁRIO e o estabelecimento PARCEIRO.
      </p>
      <p>Além disso, a PRATTU opera somente com as opções de retirada de pedidos ou consumo no local.</p>

      <h2>3. Funcionalidades principais:</h2>

      <h3>a. Gestão de Pedidos:</h3>
      <ul>
          <li><strong>Gestor de Pedidos:</strong> Permite que os estabelecimentos PARCEIROS recebam os pedidos e escolham se desejam aceitá-lo ou recusá-lo.</li>
          <li><strong>Histórico de Pedidos:</strong> Armazena o histórico de pedidos dos clientes, permitindo análises de comportamento e preferências.</li>
      </ul>

      <h3>b. Menu Digital e Personalização:</h3>
      <ul>
          <li><strong>Cardápio Interativo:</strong> Os estabelecimentos PARCEIROS podem atualizar o menu em tempo real, incluindo fotos, descrições detalhadas e valores.</li>
          <li><strong>Adição de Complementos:</strong> Os estabelecimentos PARCEIROS podem adicionar complementos aos seus itens presentes no cardápio.</li>
      </ul>

      <h3>c. Promoções:</h3>
      <ul>
          <li><strong>Promoções e Ofertas Especiais:</strong> Possibilidade de criação de promoções personalizadas para os clientes USUÁRIOS do aplicativo.</li>
          <li><strong>Desconto em toda loja:</strong> Estabelecimentos PARCEIROS podem aplicar um desconto em todos os produtos de sua loja.</li>
          <li><strong>Descontos em produtos específicos:</strong> Estabelecimentos PARCEIROS podem criar diferentes descontos para cada produto.</li>
          <li><strong>Criação de cupons de desconto:</strong> Estabelecimentos PARCEIROS podem criar cupons de desconto e configurar quando podem ser utilizados.</li>
      </ul>

      <h3>d. Análises Comerciais:</h3>
      <ul>
          <li><strong>Vendas:</strong> Oferece análises detalhadas das vendas, incluindo os produtos mais vendidos, horários de pico e preferências dos clientes.</li>
          <li><strong>Feedback dos Clientes:</strong> Coleta feedbacks dos clientes sobre a experiência com o serviço, ajudando a identificar áreas para melhoria.</li>
          <li><strong>Métricas de Desempenho:</strong> Fornece dados sobre o desempenho operacional do estabelecimento, como tempo médio de preparação.</li>
      </ul>

      <h3>e. Suporte e Atendimento ao Cliente:</h3>
      <ul>
          <li><strong>Chat em Tempo Real:</strong> Canal de atendimento para resolver dúvidas ou problemas dos clientes em tempo real.</li>
          <li><strong>Central de Ajuda:</strong> Base de perguntas e respostas frequentes sobre o uso da plataforma.</li>
      </ul>

      <h2>4. Público-Alvo:</h2>
      <p>Restaurante, Lanchonetes, Bares, Padarias, Confeitarias, Cafés, Food Trucks, Outros estabelecimentos de food service,</p>

      <h2>5. Cadastro e Conta do Restaurante - Requisitos</h2>

      <h3>a. Documentação Legal:</h3>
      <ul>
          <li><strong>Dados Pessoais:</strong> nome completo do responsável pelo estabelecimento, e-mail, número de telefone e CPF.</li>
          <li>
              <strong>Dados do Estabelecimento:</strong> CNPJ, razão social, nome da loja, faturamento mensal e tipo de empresa. 
              Também coletamos horário de funcionamento, itens do cardápio e respectivos valores.
          </li>
          <li><strong>Alvará de Funcionamento:</strong> Licença ou alvará emitido pela prefeitura ou autoridade local que autorize a operação do estabelecimento.</li>
      </ul>

      <h3>b. Informações Operacionais:</h3>
      <ul>
          <li>
              <strong>Cardápio Completo:</strong> O PARCEIRO deve apresentar um cardápio detalhado na plataforma PRATTU, incluindo todos os produtos disponíveis para venda. Cada item deve conter:
              <ul>
                  <li><strong>Descrição:</strong> Explicação clara e precisa do produto.</li>
                  <li><strong>Preços:</strong> Valores atualizados de cada item ou complementos.</li>
                  <li><strong>Fotos:</strong> Imagens de alta qualidade que representem com precisão os produtos.</li>
                  <li><strong>Informações sobre Ingredientes:</strong> Lista dos ingredientes utilizados, incluindo avisos sobre possíveis alérgenos.</li>
              </ul>
          </li>
          <li>
              <strong>Funcionamento:</strong> O PARCEIRO deve indicar os horários de funcionamento do estabelecimento e atualizá-los na plataforma em caso de mudanças.
          </li>
          <li>
              <strong>Tempo de Preparo:</strong> Configuração do tempo médio de preparo dos pedidos, usado para informar os USUÁRIOS sobre o tempo de espera.
          </li>
          <li>
              <strong>Formas de Pedidos Aceitas:</strong>
              <ul>
                  <li><strong>Retirada no Local:</strong> O pedido é preparado para que o USUÁRIO o retire no estabelecimento.</li>
                  <li><strong>Consumo no Local:</strong> O pedido é preparado para consumo nas dependências do estabelecimento.</li>
                  <li><strong>Agendamento:</strong> Possibilidade de aceitar pedidos agendados, com data e horário específicos.</li>
              </ul>
          </li>
      </ul>

      <h3>c. Integração com o Asaas:</h3>
      <ul>
          <li>
              Todas as informações descritas no item 5.a serão transferidas automaticamente para o Asaas, plataforma financeira responsável pelos pagamentos e repasses aos estabelecimentos PARCEIROS.
          </li>
          <li>
              O ASAAS é uma instituição de pagamentos certificada pelo Banco Central do Brasil. 
              Mais informações estão disponíveis em <a href="https://ajuda.asaas.com/pt-BR/articles/102021-termos-e-condicoes-de-uso" target="_blank">seus Termos e Condições</a>.
          </li>
      </ul>

      <h3>d. Conformidade com Normas da Plataforma:</h3>
      <ul>
          <li>
              <strong>Termos e Condições:</strong> Aceitação integral dos Termos e Condições da PRATTU, incluindo políticas de cancelamento, reembolsos e padrões de qualidade.
          </li>
          <li>
              <strong>Política de Privacidade:</strong> Concordância com a Política de Privacidade da PRATTU, regulando o uso e proteção dos dados fornecidos.
          </li>
          <li>
              <strong>Padrões de Qualidade:</strong> Compromisso com a qualidade dos produtos, pontualidade no preparo e eficiência no atendimento.
          </li>
          <li>
              <strong>Conformidade com Documentos do Asaas:</strong> Concordância automática com os Termos e Condições e a Política de Privacidade do Asaas.
          </li>
      </ul>

      <h3>e. Infraestrutura Tecnológica:</h3>
      <ul>
          <li>Dispositivo com acesso à internet para gerenciar pedidos e comunicações.</li>
      </ul>

      <h2>6. Regras Gerais de Uso da Plataforma</h2>

        <h3>a. Cumprimento de Normas Legais:</h3>
        <ul>
            <li><strong>Conformidade Legal:</strong> Manter toda a documentação legal em dia, como CNPJ, alvará de funcionamento e licença sanitária.</li>
            <li><strong>Adesão a Normas Sanitárias:</strong> Seguir rigorosamente as normas de higiene e segurança alimentar estabelecidas pela legislação local.</li>
        </ul>

        <h3>b. Gestão de Pedidos:</h3>
        <ul>
            <li><strong>Processamento Eficiente de Pedidos:</strong> Aceitar e processar os pedidos recebidos através da plataforma de maneira rápida e precisa.</li>
            <li><strong>Cumprimento de Prazos:</strong> Garantir que os pedidos sejam preparados e entregues dentro dos prazos estabelecidos, minimizando atrasos.</li>
            <li><strong>Comunicação com Clientes:</strong> Informar prontamente os clientes sobre eventuais problemas com o pedido, como atrasos na entrega.</li>
            <li><strong>Comunicação com a PRATTU:</strong> Informar prontamente a PRATTU caso haja a necessidade de cancelamento de um pedido já aceito pelo estabelecimento PARCEIRO.</li>
        </ul>

        <h3>c. Qualidade dos Produtos:</h3>
        <ul>
            <li><strong>Consistência na Qualidade:</strong> Assegurar que os alimentos sejam preparados com a mesma qualidade e padrão, conforme anunciado no aplicativo.</li>
            <li><strong>Manutenção da Frescura e Higiene:</strong> Garantir que todos os alimentos sejam frescos, higienicamente preparados e embalados adequadamente.</li>
        </ul>

        <h3>d. Gestão de Estoque:</h3>
        <ul>
            <li><strong>Atualização do Cardápio:</strong> Manter o cardápio atualizado no aplicativo, removendo itens fora de estoque ou indisponíveis.</li>
            <li><strong>Gestão de Ingredientes:</strong> Garantir que os ingredientes necessários estejam sempre disponíveis para evitar cancelamentos de pedidos por falta de insumos.</li>
        </ul>

        <h3>e. Uso Responsável da Plataforma:</h3>
        <ul>
            <li><strong>Conformidade com as Regras:</strong> O estabelecimento PARCEIRO deve usar a plataforma em conformidade com todas as regras e políticas estabelecidas, evitando práticas que possam prejudicar USUÁRIOS ou a reputação da plataforma.</li>
            <li><strong>Respeito à Propriedade Intelectual:</strong> O uso de imagens, descrições e outros conteúdos deve respeitar os direitos de propriedade intelectual, tanto do restaurante quanto de terceiros.</li>
            <li><strong>Proibição de Práticas Abusivas:</strong> É proibido o uso de práticas como spam, manipulação de avaliações ou tentativas de contornar o sistema da plataforma.</li>
        </ul>

        <h3>f. Pagamentos e Cobrança de Taxas:</h3>
        <ul>
            <li><strong>Taxas de Serviço:</strong> Os PARCEIROS concordam que a PRATTU cobrará uma taxa de serviço sobre cada transação realizada através da plataforma, cujo percentual será fixado em contrato. Esta taxa será automaticamente deduzida do valor total de cada venda antes do repasse dos fundos ao PARCEIRO.</li>
            <li><strong>Processamento de Pagamentos:</strong> Todos os pagamentos efetuados pelos USUÁRIOS ao PARCEIRO são processados pelo Asaas, a solução financeira parceira da PRATTU. Após a dedução automática das taxas aplicáveis, o valor restante será repassado ao PARCEIRO conforme os prazos e condições estabelecidos pela PRATTU e pelo Asaas.</li>
            <li><strong>Resolução de Discrepâncias:</strong> Caso o PARCEIRO identifique qualquer discrepância nos valores repassados, deverá notificar a PRATTU e o Asaas dentro de um prazo máximo de 10 (dez) dias úteis a partir da data de disponibilização do repasse.</li>
        </ul>

        <h3>g. Segurança e Privacidade:</h3>
        <ul>
            <li><strong>Proteção de Dados:</strong> O PARCEIRO deve proteger as informações dos clientes, seguindo as normas de privacidade e proteção de dados estabelecidas pela plataforma e pela legislação vigente.</li>
            <li><strong>Segurança de Conta:</strong> O PARCEIRO é responsável por manter a segurança de sua conta na plataforma, incluindo o uso de senhas fortes.</li>
        </ul>

        <h3>h. Feedback e Avaliações:</h3>
        <ul>
            <li><strong>Interação com Avaliações:</strong> O PARCEIRO deve monitorar continuamente avaliações e feedbacks dos clientes, utilizando essas informações para melhorar o serviço.</li>
            <li><strong>Proibição de Manipulação:</strong> É estritamente proibido manipular avaliações, seja incentivando falsos comentários positivos ou removendo críticas legítimas.</li>
        </ul>

        <h3>i. Resolução de Conflitos:</h3>
        <ul>
            <li><strong>Mediação de Conflitos:</strong> Em caso de disputas com USUÁRIOS ou com a PRATTU, o estabelecimento PARCEIRO deve cooperar para a resolução amigável e justa do conflito.</li>
            <li><strong>Cumprimento de Decisões:</strong> O restaurante deve acatar as decisões tomadas pela plataforma em processos de mediação ou resolução de conflitos.</li>
        </ul>

        <h3>j. Sustentabilidade e Responsabilidade Social:</h3>
        <ul>
            <li><strong>Uso de Embalagens Sustentáveis:</strong> Sempre que possível, adotar práticas sustentáveis, como o uso de embalagens recicláveis ou biodegradáveis.</li>
            <li><strong>Responsabilidade Social:</strong> Implementar práticas que promovam a responsabilidade social, como doação de alimentos excedentes e redução de desperdício.</li>
        </ul>

        <h2>7. Política de Cancelamento e Reembolso para Estabelecimentos</h2>

        <h3>a. Cancelamento de Pedidos com Reembolso Antes da Retirada pelos USUÁRIOS:</h3>
        <ul>
            <li><strong>Antes da Aceitação do Pedido:</strong> O PARCEIRO fica ciente da possibilidade de cancelamento de pedido pelos USUÁRIOS antes que o mesmo seja aceito e o preparo iniciado. Nenhuma cobrança será aplicada ao PARCEIRO neste caso, e o USUÁRIO terá direito ao reembolso total.</li>
            <li><strong>Pedidos Agendados:</strong> Para pedidos agendados, o PARCEIRO fica ciente da possibilidade de cancelamento pelo USUÁRIO, até que o estabelecimento comece o preparo.</li>
            <li><strong>Atrasos no Tempo Prometido:</strong> Caso o PARCEIRO ultrapasse 50% do prazo de preparo prometido no momento da compra, o USUÁRIO poderá cancelar o pedido e solicitar reembolso.</li>
        </ul>

        <h3>b. Cancelamento de Pedidos com Reembolso Após a Retirada pelos USUÁRIOS:</h3>
        <ul>
            <li><strong>Condições para Reembolso:</strong> O PARCEIRO fica ciente que haverá reembolso ao USUÁRIO se forem identificados problemas como produtos estragados, itens incorretos ou incompletos.</li>
            <li><strong>Verificação pelo PARCEIRO:</strong> É responsabilidade do PARCEIRO garantir que todos os produtos estejam corretos, completos e de boa qualidade no momento da entrega ao USUÁRIO.</li>
        </ul>

        <h3>c. Cancelamento de Pedidos pelo Estabelecimento PARCEIRO:</h3>
        <ul>
            <li><strong>Cancelamento pelo Estabelecimento:</strong> Caso o estabelecimento PARCEIRO, após a aceitação do pedido, por alguma razão não possa atendê-lo, deverá contatar o suporte da PRATTU para adoção das medidas cabíveis.</li>
        </ul>

        <h3>d. Processo de Reembolso:</h3>
        <ul>
            <li><strong>Cooperação do PARCEIRO:</strong> O PARCEIRO deve cooperar com o suporte ao cliente da PRATTU, fornecendo todas as informações e materiais necessários para a análise do pedido de reembolso.</li>
        </ul>

        <h3>e. Comunicação:</h3>
        <ul>
            <li><strong>Informação sobre Reembolso:</strong> O PARCEIRO será informado pelo Asaas sobre cada reembolso processado.</li>
        </ul>

        <h3>f. Cancelamento de Pedidos sem Reembolso:</h3>
        <ul>
            <li><strong>Pedido Não Retirado:</strong> Quando o USUÁRIO não retirar o pedido no local conforme combinado.</li>
            <li><strong>Produtos Aceitos na Retirada:</strong> Quando os produtos forem aceitos no ato da retirada e posteriormente rejeitados sem motivo válido.</li>
            <li><strong>Falta de Problemas Comprovados:</strong> Quando não forem identificados problemas comprovados nos produtos.</li>
            <li><strong>Status de Pedido “Em Preparo” ou “Concluído”:</strong> Quando o pedido já estiver em preparo, aguardando retirada, ou tiver sido concluído.</li>
        </ul>

        <h2>8. Responsabilidades do Estabelecimento PARCEIRO</h2>
        <ul>
            <li>Emitir Nota Fiscal a cada compra realizada pelo USUÁRIO.</li>
            <li>Cumprir todas as leis, regulamentos e normas aplicáveis no uso da plataforma.</li>
            <li>Fornecer informações verdadeiras e precisas.</li>
            <li>Respeitar os direitos dos USUÁRIOS enquanto consumidores.</li>
            <li>Realizar transações de forma honesta e transparente.</li>
            <li>Adotar as diretrizes e políticas da plataforma, incluindo Termos de Uso e Política de Privacidade.</li>
            <li>Não publicar, compartilhar ou disseminar conteúdo ilegal, ofensivo, discriminatório, difamatório ou que viole os direitos de terceiros.</li>
            <li>Garantir que os produtos anunciados estejam em conformidade com os padrões estabelecidos pela plataforma.</li>
            <li>Respeitar a privacidade dos USUÁRIOS e não compartilhar informações pessoais de terceiros sem consentimento.</li>
            <li>Reconhecer que a plataforma pode remover conteúdo que viole suas políticas ou leis aplicáveis.</li>
            <li>Reportar conteúdo ou comportamento inadequado que viole os termos de uso da plataforma.</li>
            <li>Utilizar a plataforma de maneira que não interfira no funcionamento normal do serviço ou prejudique os USUÁRIOS.</li>
            <li>Evitar práticas de spam, phishing ou qualquer forma de fraude.</li>
            <li>Manter-se informado sobre atualizações nos Termos de Uso e políticas da plataforma.</li>
            <li>Adaptar-se às mudanças e seguir as novas diretrizes conforme necessário.</li>
            <li>Não utilizar a plataforma para fins ilícitos.</li>
        </ul>

        <h2>9. Suspensão e Cancelamento da Conta</h2>
        <p>A PRATTU se reserva o direito de suspender, bloquear, desativar, cancelar ou encerrar unilateralmente o acesso do estabelecimento PARCEIRO à plataforma a qualquer momento, com ou sem aviso prévio, caso sejam identificadas violações das Regras Gerais de Uso e Responsabilidades descritas nos itens 6 e 8.</p>

        <h3>a. Motivos para Suspensão ou Cancelamento:</h3>
        <ul>
            <li>Violação das Regras da Plataforma: Descumprimento das regras estabelecidas nestes Termos e Condições ou em outras políticas aplicáveis da PRATTU.</li>
            <li>Baixa Qualidade de Serviço: Manutenção constante de padrões de qualidade abaixo do exigido, incluindo reclamações recorrentes de USUÁRIOS sobre o atendimento, a qualidade dos produtos, o tempo de entrega ou qualquer outro aspecto do serviço prestado.</li>
            <li>Práticas Fraudulentas: Envolvimento em atividades fraudulentas, como manipulação de avaliações, dados incorretos ou enganosos, ou quaisquer práticas que comprometam a integridade da plataforma PRATTU e a confiança dos USUÁRIOS.</li>
            <li>Cláusulas Contratuais: Descumprimento de quaisquer cláusulas do Contrato de Adesão e Uso da Plataforma PRATTU.</li>
        </ul>

        <h3>b. Notificação e Direito de Apelação:</h3>
        <ul>
            <li>Notificação: Em caso de suspensão, desativação ou cancelamento da conta, o estabelecimento PARCEIRO será notificado pela PRATTU sobre o motivo da ação tomada.</li>
            <li>Direito de Apelação: O PARCEIRO tem o direito de apelar da decisão, apresentando justificativas, provas ou argumentos que considere relevantes para a reavaliação do caso. O processo de apelação deve ser iniciado dentro de um prazo de 10 (dez) dias úteis a partir da data de notificação, por meio do canal designado pela PRATTU para este fim.</li>
            <li>A decisão final sobre a apelação será tomada pela PRATTU após a análise completa das justificativas apresentadas, sendo comunicada ao PARCEIRO no prazo máximo de 15 (quinze) dias úteis.</li>
        </ul>

        <h2>10. Políticas de Segurança de Senha:</h2>
        <h3>a. Criação de Senhas:</h3>
        <ul>
            <li><strong>Requisitos de Complexidade:</strong> A senha deve ter no mínimo 6 caracteres, incluir pelo menos uma letra maiúscula, uma letra minúscula, um número e um caractere especial (como !, @, #, $, etc.).</li>
            <li><strong>Proibição de Senhas Comuns:</strong> Senhas como "123456", "senha", "admin", ou outras combinações triviais são proibidas. O sistema deve bloquear a utilização de senhas que constam em listas de senhas comuns ou já comprometidas.</li>
        </ul>

        <h3>b. Armazenamento e Recuperação de Senhas:</h3>
        <ul>
            <li><strong>Armazenamento Seguro:</strong> As senhas serão armazenadas no banco de dados da PRATTU de forma segura. A plataforma evitará o armazenamento de senhas em texto simples em qualquer parte do sistema.</li>
            <li><strong>Recuperação de Senha:</strong> Em caso de esquecimento da senha, o PARCEIRO poderá solicitar a recuperação por meio de um processo de autenticação através do e-mail cadastrado na PRATTU. Um link de redefinição de senha será enviado ao e-mail registrado.</li>
        </ul>

        <h2>11. Uso dos Dados Coletados</h2>
        <p>
          Todos os dados coletados no ato do cadastro do estabelecimento PARCEIRO na Plataforma PRATTU e relacionados no item 5 são essenciais para a identificação, comunicação e operacionalização do serviço entre PRATTU, PARCEIROS e o sistema de pagamento Asaas.
        </p>

        <h3>a. Finalidade da Coleta</h3>
        <ul>
          <li><strong>Operacionalização do Serviço:</strong> Os dados coletados são utilizados para permitir a funcionalidade completa da plataforma, incluindo a criação de contas, configuração de cardápios e gestão de pedidos.</li>
          <li><strong>Melhoria de Serviços:</strong> Informações agregadas e anônimas podem ser usadas para análise e melhoria contínua dos serviços oferecidos pela PRATTU.</li>
        </ul>

        <h3>b. Compartilhamento de Dados com Terceiros</h3>
        <ul>
          <li><strong>Asaas:</strong> Os dados dos PARCEIROS são compartilhados com o Asaas para viabilizar o processamento de pagamentos e repasses financeiros.</li>
          <li><strong>Parceiros Operacionais:</strong> Informações podem ser compartilhadas com parceiros operacionais que auxiliem na prestação do serviço, sempre respeitando as diretrizes de privacidade.</li>
          <li><strong>Obrigações Legais:</strong> A PRATTU poderá compartilhar dados com autoridades competentes quando necessário para o cumprimento de obrigações legais.</li>
        </ul>

        <h3>c. Direitos dos Estabelecimentos PARCEIROS</h3>
        <ul>
          <li><strong>Acesso aos Dados:</strong> Os PARCEIROS têm o direito de acessar e corrigir os dados pessoais e comerciais fornecidos à PRATTU.</li>
          <li><strong>Solicitação de Exclusão:</strong> Os PARCEIROS podem solicitar a exclusão de seus dados, respeitando os limites legais e contratuais.</li>
          <li><strong>Portabilidade de Dados:</strong> É possível solicitar a portabilidade dos dados para outros serviços, mediante requisição formal.</li>
        </ul>

        <h2>12. Promoções e Ofertas</h2>
        <p>
          A PRATTU oferece aos PARCEIROS a oportunidade de atrair mais clientes e aumentar suas vendas por meio da criação de promoções e ofertas personalizadas. Os PARCEIROS podem configurar e gerenciar diversas promoções diretamente na plataforma, seguindo as diretrizes e condições estabelecidas abaixo:
        </p>

        <h3>a. Termos para Participação em Promoções</h3>
        <ul>
          <li><strong>a.1 Desconto em Toda a Loja:</strong> O PARCEIRO pode aplicar um desconto percentual sobre todos os produtos disponíveis no cardápio. Essa promoção é ideal para atrair uma maior base de clientes ao oferecer uma vantagem geral em todo o menu.</li>
          <li><strong>a.2 Descontos em Itens Específicos:</strong> O PARCEIRO pode selecionar itens específicos do cardápio para receberem descontos exclusivos. Isso pode ser utilizado para promover novos produtos, itens com menor saída ou ofertas sazonais.</li>
          <li><strong>a.3 Cupons de Desconto:</strong> O PARCEIRO pode criar cupons de desconto que os USUÁRIOS podem aplicar no momento do checkout. Essa estratégia é eficaz para incentivar compras repetidas e aumentar a fidelização dos clientes.</li>
        </ul>

        <h3>b. Regras Específicas para os Descontos</h3>
        <ul>
          <li><strong>Não Acumulatividade:</strong> Em situações onde existam dois ou mais descontos ativos para o mesmo produto, o desconto de maior valor será automaticamente aplicado no checkout. A acumulação de múltiplos descontos sobre o mesmo item não é permitida, garantindo assim transparência e simplicidade na aplicação das promoções.</li>
          <li><strong>Validade:</strong> Os PARCEIROS são responsáveis por definir claramente as condições de validade dos cupons, como a data de início e término da promoção.</li>
          <li><strong>Monitoramento e Ajustes:</strong> O PARCEIRO tem a capacidade de monitorar a eficácia das promoções e fazer ajustes conforme necessário. Isso inclui a alteração de condições de validade, ajustes nos valores de desconto, ou até a interrupção antecipada de uma promoção em caso de necessidade.</li>
        </ul>

        <h2>13. Interações com Clientes</h2>
        <h3>a. Diretrizes para Interação com Clientes</h3>
        <ul>
          <li><strong>Proteção de Dados:</strong> Os PARCEIROS não terão acesso direto aos dados pessoais dos clientes, como endereço ou formas de contatos. O único dado que será passado aos estabelecimentos PARCEIROS é o nome do USUÁRIO, para fins relacionados à identificação para retirada do pedido. Toda a comunicação necessária será intermediada pela PRATTU.</li>
          <li><strong>Suporte e Comunicação:</strong> A PRATTU é responsável por qualquer tipo de comunicação direta com os clientes, incluindo notificações, atualizações de pedido e atendimento ao cliente.</li>
        </ul>

        <h3>b. Moderação de Avaliações e Feedback</h3>
        <ul>
          <li><strong>Acesso às Avaliações:</strong> Os PARCEIROS terão acesso às avaliações e feedbacks deixados pelos clientes, mas não poderão respondê-los diretamente. Esse acesso é apenas para fins de melhoria interna.</li>
          <li><strong>Moderação e Remoção:</strong> A PRATTU se reserva o direito de moderar e remover avaliações que sejam ofensivas, inadequadas ou que não reflitam a experiência real do cliente.</li>
        </ul>

        <h3>c. Responsabilidades sobre o Conteúdo Gerado</h3>
        <ul>
          <li><strong>Conformidade:</strong> Os PARCEIROS devem garantir que qualquer conteúdo que eles carreguem na plataforma, como descrições de produtos e imagens, esteja em conformidade com as políticas da PRATTU e não infrinja direitos de terceiros.</li>
          <li><strong>Conteúdo Autêntico:</strong> É de responsabilidade do PARCEIRO garantir que todo o conteúdo seja verdadeiro e não induza os consumidores ao erro.</li>
        </ul>

        <h2>14. Direitos de Propriedade Intelectual</h2>
        <h3>a. Propriedade do Conteúdo Fornecido</h3>
        <ul>
          <li><strong>Licenciamento:</strong> Ao fornecer conteúdo à PRATTU, os PARCEIROS concedem à PRATTU uma licença não exclusiva, global e livre de royalties para usar, exibir, modificar e distribuir o conteúdo na plataforma e em materiais promocionais.</li>
          <li><strong>Responsabilidade por Violações:</strong> Os PARCEIROS são responsáveis por garantir que o conteúdo fornecido não infrinja direitos autorais, marcas ou outros direitos de propriedade intelectual de terceiros.</li>
        </ul>

        <h3>b. Uso de Marcas e Logos</h3>
        <ul>
          <li>A PRATTU se reserva o direito de uso de marcas e logos dos estabelecimentos PARCEIROS em campanhas publicitárias ou materiais promocionais sem necessidade de autorização prévia do PARCEIRO.</li>
        </ul>

        <h3>c. Propriedade Intelectual dos Restaurantes</h3>
        <ul>
          <li><strong>Proteção de Conteúdo:</strong> Os PARCEIROS mantêm todos os direitos sobre sua propriedade intelectual, incluindo marcas, logos e conteúdo de cardápio, desde que este tenha sido criado por eles e não infrinja os direitos de terceiros.</li>
        </ul>

        <h2>15. Limitação de Responsabilidade</h2>
        <h3>a. Isenção de Garantias</h3>
        <ul>
          <li><strong>Funcionamento da Plataforma:</strong> A PRATTU não garante que a plataforma estará disponível ininterruptamente ou livre de erros. Eventuais falhas técnicas serão tratadas o mais rápido possível, mas não são passíveis de compensação.</li>
        </ul>

        <h3>b. Limites de Responsabilidade</h3>
        <ul>
          <li><strong>Perdas Indiretas:</strong> A PRATTU não se responsabiliza por perdas indiretas, como perda de receita, lucros cessantes, danos emergentes ou perda de oportunidade, decorrentes do uso da plataforma.</li>
        </ul>

        <h3>c. Responsabilidades do Restaurante</h3>
        <ul>
          <li><strong>Conformidade com Leis:</strong> O PARCEIRO é o único responsável por garantir que suas operações estejam em conformidade com as leis e regulamentações locais, incluindo normas sanitárias e de segurança alimentar.</li>
          <li><strong>Indenização:</strong> O PARCEIRO concorda em indenizar a PRATTU contra quaisquer reivindicações, perdas ou danos resultantes de violações destes termos ou de qualquer ato ou omissão do PARCEIRO.</li>
        </ul>

        <h2>16. Modificações nos Termos</h2>
        <h3>a. Política de Notificação de Alterações nos Termos</h3>
        <ul>
          <li><strong>Notificação Prévia:</strong> A PRATTU se reserva o direito de modificar os termos de uso a qualquer momento. Quaisquer mudanças substanciais nos termos serão comunicadas aos PARCEIROS por meio de notificação via e-mail ou através de um aviso dentro da própria plataforma.</li>
          <li><strong>Consulta aos Termos Atualizados:</strong> Recomenda-se que os PARCEIROS revisem periodicamente os termos de uso para se manterem informados sobre possíveis alterações.</li>
        </ul>

        <h3>b. Aceitação dos Novos Termos</h3>
        <ul>
          <li><strong>Aceitação Implícita:</strong> A continuidade do uso da plataforma PRATTU após a notificação de alterações será considerada como aceitação tácita dos novos termos.</li>
          <li><strong>Contestação:</strong> Caso o PARCEIRO não concorde com os novos termos, ele terá o direito de contestar as alterações e, se necessário, encerrar sua conta.</li>
        </ul>

        <h2>16. Resolução de Disputas</h2>
        <ul>
            <li>
                <h3>a. Procedimento para Resolução de Disputas:</h3>
                <ul>
                    <li>Tentativa de Resolução Amigável: Em caso de disputa entre a PRATTU e o PARCEIRO, ambas as partes concordam em tentar resolver a questão de forma amigável, por meio de negociações diretas e, se necessário, mediação.</li>
                    <li>Mediação e Arbitragem: Se a resolução amigável não for possível, as partes poderão optar por submeter a disputa à mediação ou arbitragem, conforme as regras da câmara de arbitragem escolhida pelas partes.</li>
                </ul>
            </li>
            <li>
                <h3>b. Jurisdição e Leis Aplicáveis:</h3>
                <ul>
                    <li>Legislação Vigente: Estes termos de uso são regidos pelas leis da República Federativa do Brasil.</li>
                    <li>Foro Competente: Fica eleito o Foro da Comarca de São Paulo/SP, Brasil, para dirimir quaisquer questões relativas a estes termos que não puderem ser resolvidas por mediação ou arbitragem.</li>
                </ul>
            </li>
        </ul>

        <h2>17. Contato para Suporte</h2>
        <ul>
            <li>
                <h3>a. Informações de Contato para Suporte ao Estabelecimento PARCEIRO:</h3>
                <ul>
                    <li>Canal de Suporte: Os PARCEIROS podem entrar em contato com a equipe de suporte da PRATTU através do e-mail suporte@prattuapp.com.br ou pela própria plataforma, acessando a aba de “Suporte”. O suporte está disponível de segunda a sexta-feira, das 9h às 18h.</li>
                    <li>Tempo de Resposta: A PRATTU se compromete a responder às solicitações de suporte no prazo máximo de 48 horas úteis.</li>
                </ul>
            </li>
            <li>
                <h3>b. Procedimento para Envio de Feedback:</h3>
                <ul>
                    <li>Envio de Feedback: Os PARCEIROS são incentivados a enviar feedbacks e sugestões sobre a plataforma PRATTU. O envio pode ser feito pelo e-mail de suporte ou através da página de “Suporte” localizada na plataforma PRATTU.</li>
                    <li>Análise de Feedback: Todos os feedbacks serão analisados pela equipe da PRATTU, que poderá entrar em contato para discutir melhorias ou esclarecer dúvidas.</li>
                </ul>
            </li>
        </ul>

        <h2>18. Segurança e Conformidade</h2>
        <ul>
            <li>
                <h3>a. Medidas de Segurança Adotadas:</h3>
                <ul>
                    <li>Proteção de Dados: A PRATTU adota medidas de segurança técnica e administrativa adequadas para proteger os dados dos PARCEIROS contra acesso não autorizado, uso indevido ou divulgação.</li>
                </ul>
            </li>
            <li>
                <h3>b. Conformidade com Leis e Regulamentos:</h3>
                <ul>
                    <li>LGPD: A PRATTU se compromete a cumprir a Lei Geral de Proteção de Dados (LGPD) do Brasil, garantindo a privacidade e segurança dos dados pessoais dos PARCEIROS e clientes.</li>
                    <li>Outros Regulamentos: Além da LGPD, a PRATTU se compromete a seguir todas as regulamentações aplicáveis ao setor de comércio eletrônico e food service.</li>
                </ul>
            </li>
        </ul>

        <h2>19. Transferência Internacional de Dados</h2>
        <ul>
            <li>Localização dos Servidores: Os dados coletados pela PRATTU podem ser armazenados em servidores localizados fora do Brasil. A PRATTU garante que todas as transferências internacionais de dados pessoais estarão de acordo com as exigências legais e seguirão as melhores práticas de proteção de dados.</li>
            <li>Acordos de Transferência: Quando necessário, a PRATTU firmará acordos de transferência de dados com terceiros para garantir o cumprimento das normas de proteção de dados aplicáveis.</li>
        </ul>

        <h2>20. Outros Aspectos Legais</h2>
        <ul>
            <li>
                <h3>a. Definição de Força Maior:</h3>
                <ul>
                    <li>A PRATTU não será responsabilizada por falhas ou atrasos no cumprimento de suas obrigações devido a eventos de força maior, incluindo, mas não se limitando a desastres naturais, pandemias, atos de guerra, greves ou outras situações fora do controle razoável da empresa.</li>
                </ul>
            </li>
            <li>
                <h3>b. Impacto nos Serviços:</h3>
                <ul>
                    <li>Durante a ocorrência de um evento de força maior, a PRATTU fará todos os esforços razoáveis para minimizar o impacto nos serviços prestados, mas poderá ser necessário suspender ou limitar o acesso à plataforma temporariamente.</li>
                </ul>
            </li>
        </ul>

        <h2>21. Disposições Gerais</h2>
        <ul>
            <li>
                <h3>a. Cláusulas Gerais e Finais:</h3>
                <ul>
                    <li>Integralidade do Acordo: Estes termos de uso constituem o acordo integral entre a PRATTU e os PARCEIROS, substituindo quaisquer outros acordos ou entendimentos anteriores.</li>
                    <li>Renúncia: A eventual falha da PRATTU em exigir o cumprimento de qualquer cláusula destes termos não constituirá renúncia ao direito de exigir o cumprimento posterior.</li>
                </ul>
            </li>
            <li>
                <h3>b. Validade dos Termos:</h3>
                <ul>
                    <li>Data de Efeito: Estes termos entram em vigor na data de aceitação pelo PARCEIRO e permanecerão válidos enquanto o PARCEIRO continuar a usar a plataforma PRATTU.</li>
                </ul>
            </li>
            <li>
                <h3>c. Consequências para Violação dos Termos:</h3>
                <ul>
                    <li>Suspensão e Encerramento de Conta: A PRATTU se reserva o direito de suspender ou encerrar a conta do PARCEIRO em caso de violação dos termos de uso, sem prejuízo de outras medidas legais cabíveis.</li>
                    <li>Responsabilidade Civil: O PARCEIRO será responsável por quaisquer danos causados à PRATTU ou a terceiros em decorrência da violação destes termos.</li>
                </ul>
            </li>
        </ul>

        <p>Esses Termos e Condições de Uso foram elaborados para garantir clareza e transparência na relação entre a PRATTU e seus estabelecimentos PARCEIROS.</p>


    </div>
  </div>
</template>

<script>
import Navbar from "../components/Navbar.vue";

export default {
  name: "Terms",
  components: {
    Navbar,
  },
};
</script>

<style lang="scss" scoped>
.container-data {
  padding-top: 70px;
  max-width: 100%;
}

.content {
  max-width: 1280px; /* Define a largura máxima do conteúdo */
  margin: 0 auto; /* Centraliza o conteúdo */
  padding: 20px;
  font-size: 16px;
  line-height: 1.5;
  color: #333;
}

.content h1, .content h2, .content h3 {
  margin-top: 20px;
  font-weight: bold;
}

.content ul {
  list-style-type: disc;
  margin-left: 20px;
}

.content a {
  color: #007bff;
  text-decoration: none;
}

.content a:hover {
  text-decoration: underline;
}
</style>
